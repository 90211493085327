import React from "react";

function Footer(props) {

    const license = "Dumri • © 2024";

    const containerStyle = {
        height: props.height,
        display: "grid",
        boxSizing: "border-box",
        minWidth: props.minWidth,
        backgroundColor: props.backgroundColor,

        justifySelf: "center",
        placeItems: "center",
    }

    return (
        <footer style={containerStyle}>
            <p className="license">{license}</p>
        </footer>
    );
};

export default Footer;