import React from "react";

function Project(props) {

    const learnMore = "Learn More >>";
    console.log(props);

    const allContainer = {
        // maxHeight: "550px",
        border: "2px solid #F5F5F5",
        borderRadius: "16px",
        boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",

        padding: "3.69% 5.04% 3.69% 5.04%",
        display: "grid",
        gridTemplateRows: "repeat(5, 1fr)",
        rowGap: "3.69%",
        overflow: "hidden"
    }

    const detailContainer = {
        gridRow: "4 / 6",

        display: "grid",
        gridTemplateRows: "repeat(5, 0.37fr)",
        rowGap: "0.92%",
    }

    const projectPictureStyle = {
        width: "100%",
        height: "100%",
        maxHeight: "200px",
        gridRow: "2 / 4",
        objectFit: "cover",
    }

    const detailStyle = {
        gridRow: " 1 / 4",
        alignSelf: "start"
    }

    const skillsStyle = {
        gridRow: "4 / 5",
        alignSelf: "end",
        justifySelf: "start"
    }

    const learnMoreStyle = {
        gridRow: "5 / 6",
        alignSelf: "end",
        color: "#495464"
    }


    return (
        <article style={allContainer}>
            <h4>{props.name}</h4>
            <img style={projectPictureStyle} src={props.picture} alt={`${props.id}'s project`}/>
                <div style={detailContainer}>
                    <p style={detailStyle}>{props.description}</p>
                    <p style={skillsStyle} className="skills">{props.skills}</p>
                    <p style={learnMoreStyle} className="learnMore">
                    <a
                        href={props.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={props.name}
                    >{learnMore}</a>
                    </p>
                </div>
        </article>
    );
};

export default Project;