import React from "react";

function About(props) {

    const name = "Hello, I'm Gex";
    const title = "Business Analyst";
    const introduction = "I'm a business analyst with a background in software development, specialize in front-end web development and business analysis. My unique blend of technical skills and business acumen allows me to bridge gaps effectively and deliver impactful results.";
    const profilePic = `${process.env.PUBLIC_URL}/pictures/profile.jpg`;

    const containerStyle = {
        height: props.height,
        display: "grid",
        placeItems: "center",
        boxSizing: "border-box",
        minWidth: props.minWidth,
        padding: "0px 30px 0px 30px"
    };

    const innerContainerStyle = {
        height: "66.5%",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        columnGap: "3%",
        gridTemplateRows: "repeat(8, 1fr)",
        rowGap: "1.6%",
        position: "relative",
    };

    const backgroundStyle = {
        gridRow: "1 / -1",
        gridColumn: "1 / 2",
        backgroundImage: `url(${profilePic})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        borderRadius: "161.8px"
    };

    const nameStyle = {
        gridRow: "2 / 4",
        gridColumn: "2 / 4",
        alignSelf: "end",
        zIndex: 2
    };

    const titleStyle = {
        gridRow: "4 / 5",
        gridColumn: "2 / 4",
        alignSelf: "end",
        zIndex: 2
    };

    const introductionStyle = {
        gridRow: "5 / 8",
        gridColumn: "2 / 4",
        alignSelf: "start",
        zIndex: 2
    };

    return (
        <header style={containerStyle} className="container">
            <div style={innerContainerStyle}>
                <div style={backgroundStyle}></div>
                <h1 style={nameStyle}>{name}</h1>
                <h2 style={titleStyle}>{title}</h2>
                <p style={introductionStyle} className="introduction">{introduction}</p>
            </div>
        </header>
    );
};

export default About;
