import React from "react";
import { iconList } from "./Navigation";

export const socialMedia = {
    linkedin: "https://www.linkedin.com/in/dumri-ketupanya/",
    github: "https://github.com/dumriketupanya/",
    medium: "https://medium.com/@dumriketupanya",
    email: "email: dumriketupanya@gmail.com",
    phone: "tel: +660954941555"
};

function Contact(props) {

    const outerContainerStyle = {
        backgroundColor: props.backgroundColor,
        width:"100%",
        minWidth: props.minWidth,
        padding: "30px 0px 30px 0px",
        marginTop: "20px"
    }

    const containerStyle = {
        height: props.height,
        display: "grid",
        boxSizing: "border-box",
        minWidth: props.minWidth,
        padding: "0px 30px 0px 30px",

        gridTemplateRows: "repeat(2, 1fr)",
        columnGap: "80px",
        gridTemplateColumns: "repeat(2, 1fr)",
    };

    const contactInfoStyle = {
        gridColumn: "1",
        gridRow: "1"
    }

    const socialStyle = {
        gridColumn: "2",
        gridRow: "1"
    }

    const contactDetailStyle = {
        minWidth: "350px"
    }

    const iconSize = {
        width: "25px",
        height: "auto"
    }

    const socialListStyle = {
        display: "flex",
        listStyle: "none",
        paddingRight: 0,
        margin: 0,
    }

    const socialItemStyle = {
        margin: "0px 25px 0px 5px",

        display: "grid",
        justifySelf: "center"
    }

    return (
        <div style={outerContainerStyle}>
                    <div className="container" style={containerStyle}  id="contact">
            <h4 style={contactInfoStyle}>Contact Information</h4>
            <ul  className="contactFooter" style={contactDetailStyle}>
                <li>
                    <a
                        href={socialMedia.email}
                        aria-label="Email"
                    >
                        {socialMedia.email}
                    </a>
                </li>
                <li>
                    <a 
                        href={socialMedia.phone} 
                        aria-label="Phone"
                    >
                        {socialMedia.phone}
                    </a>
                </li>
            </ul>
            <h4 style={socialStyle}>Social Media</h4>
            <ul style={socialListStyle}>
                <li style={socialItemStyle}>
                    <a
                        href={socialMedia.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="LinkedIn"
                    >
                        <img src={iconList.linkedinIcon}
                        alt="LinkedIn Icon"
                        style={iconSize}/>
                    </a>
                </li>
                <li style={socialItemStyle}>
                    <a
                        href={socialMedia.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="GitHub"
                    >
                        <img src={iconList.githubIcon}
                        alt="LinkedIn Icon"
                        style={iconSize}/>
                    </a>
                </li>
                <li style={socialItemStyle}>
                    <a
                        href={socialMedia.medium}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Medium"
                    >
                    <img src={iconList.mediumIcon}
                        alt="LinkedIn Icon"
                        style={iconSize}/>
                    </a>
                </li>
            </ul>
        </div>
        </div>

    );
}

export default Contact;
