import React from "react";
import Project from "./Project";

function FeatureProject(props) {

    const projectList = [
        {
            id: 1,
            name: "Restaurant Website with Reservation Booking System",
            picture: `${process.env.PUBLIC_URL}/pictures/littlelemon.jpg`,
            description: "A Little Lemon restaurant website with an online reservation system for easy table booking, menu browsing, and reservation management. Includes server-side integration for efficient handling of reservations and updates.",
            skills: "Skills: React, Node.js, CSS, HTML, Web Development",
            url: "https://github.com/dumriketupanya/little-lemon-front-end-project"
        },
        {
            id: 2,
            name: "Portfolio Volatility Reduction via Variation Analysis",
            picture: `${process.env.PUBLIC_URL}/pictures/portOptimized.jpg`,
            description: "Designed and implemented a financial model to reduce portfolio volatility using price correlation analysis, leading to lower risk while maintaining high returns. The model incorporated statistical techniques to optimize asset allocation and enhance overall portfolio stability.",
            skills: "Skills: JAVA, Risk Management, Data Analysis",
            url: "https://medium.com/@dumriketupanya/to-the-moon-%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%AB%E0%B8%A7%E0%B8%B2%E0%B8%94%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B8%A2%E0%B8%A7%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%99%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B8%A3%E0%B8%96%E0%B9%84%E0%B8%9F%E0%B9%80%E0%B8%AB%E0%B8%B2%E0%B8%B0-%E0%B8%97%E0%B8%B3%E0%B8%A2%E0%B8%B1%E0%B8%87%E0%B9%84%E0%B8%87%E0%B9%84%E0%B8%9B%E0%B8%94%E0%B8%B9%E0%B8%81%E0%B8%B1%E0%B8%99-1-2-d2fec387e1e9"
        },
        {
            id: 3,
            name: "Integrated MRP Program for Food Production",
            picture: `${process.env.PUBLIC_URL}/pictures/mrp.jpg`,
            description: "Integrated and designed an MRP system with an Order Management System to streamline and optimize the food production process, enhancing efficiency and synchronization. This solution improved inventory management, reduced lead times, and ensured accurate production planning.",
            skills: "Skills: Python, SQL, Operation Management ",
            url: "https://github.com/dumriketupanya/MRP-DessertProduction"
        },
        {
            id: 4,
            name: "Customer Segmentation Analysis in Food Industry",
            picture: `${process.env.PUBLIC_URL}/pictures/marketResearch.jpg`,
            description: "Explored a sample customer group and conducted customer segmentation analysis to identify target market segments for launching and penetrating food products. This analysis involved designing surveys, applying cluster analysis and visualizing the results.",
            skills: "Skills: R, Cluster Analysis, Hypothesis Testing ",
            url: "https://github.com/dumriketupanya/Customer-Segmentation"
        },
    ];

    const containerStyle = {
        height: props.height,
        display: "grid",
        boxSizing: "border-box",
        minWidth: props.minWidth,

        gridTemplateRows: "repeat(7, 1fr)",
        rowGap: "2.85%",
        marginRow: "2.14%",

        
        gridTemplateColumns: "repeat(2, 1fr)",
    };

    const contentBoxStyle = {
        display: "grid",
        gridRow: "2 / 8",
        gridColumn: "1 / 3",

        gridTemplateColumns: "repeat(auto-fill, 600px)",
        minWidth: "650px",
        columnGap: "4%",
        rowGap: '40px',
        justifyContent: "Space-around",
    };

    const headerStyle = {
        gridRow: "1 / auto",
        gridColumn: "1 / 3",
        alignSelf: "end",
        justifySelf: "start",
        transform: "translateX(30px)"
    };

    return (
        <main className="container" style={containerStyle}  id="projects">
            <h3 style={headerStyle}>Feature Projects</h3>
                <section style={contentBoxStyle}>
                    {projectList.map(project => (
                        <Project
                            key={project.id}
                            name={project.name}
                            picture={project.picture}
                            description={project.description}
                            skills={project.skills}
                            url={project.url}
                        />
                    ))}
                </section>
        </main>
    );
};

export default FeatureProject;