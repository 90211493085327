import './App.css';
import Navigation from './components/Navigation';
import About from './components/About';
import FeatureProject from './components/FeatureProject';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';

function App() {

  const minWidth = "650px";

  return (
    <>
      <Navigation height="auto" minWidth={minWidth} backgroundColor="#E8E8E8"/>
        <Routes>
        <Route path="/" element={
            <>
              <About height="800px" minWidth={minWidth}/>
              <FeatureProject height="auto" minWidth={minWidth}/>
            </>
          }/>

        <Route path="/projects" element={
            <>
            </>
          }/>
        </Routes>
      <Contact height="fit-content" minWidth={minWidth} backgroundColor="#F4F4F2"/>
      <Footer height="40px" minWidth={minWidth} backgroundColor="#E8E8E8"/>
    </>
  );
}

export default App;
