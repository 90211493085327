import React from "react";
import { socialMedia } from './Contact';

export const iconList = {
    linkedinIcon: `${process.env.PUBLIC_URL}/pictures/linkedin.png`,
    githubIcon: `${process.env.PUBLIC_URL}/pictures/github.png`,
    mediumIcon: `${process.env.PUBLIC_URL}/pictures/medium.png`,
}

function Navigation(props) {

    const iconSize = {
        width: "30px",
        height: "auto",
    }

    const outerContainerStyle = {
        display: "grid",
        padding: "5px",
        backgroundColor: props.backgroundColor,
        placeItems: "center",
        width:"100%",
        minWidth: props.minWidth,
        height: props.height,
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
        position: "fixed",
        zIndex: 1000,
    }

    const containerStyle = {
        display: "grid",
        boxSizing: "border-box",
        minWidth: props.minWidth,

        gridTemplateColumns: "repeat(2, 1fr)",
        columnGap: "8%",
    }

    const navRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
    }

    const inPageRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        justifyItems: "center",
        alignItems: "center"
    }

    const navLinkStyle = {
        display: "grid",
        justifyContent: "center",
        alignContent: "center"
    }

    const projectLink = {
        gridColumn: "1 / 3",
        justifySelf: "end"
    }

    const contactLink = {
        gridColumn: "3 / 5",
        justifySelf: "center"
    }

    return (
        <nav style={outerContainerStyle}>
            <div className="container" style={containerStyle}>
                <ul style={navRowStyle}>
                    <li style={navLinkStyle}  className="hideOnMobile">
                        <a
                            href={socialMedia.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="LinkedIn"
                            >
                            <img
                                src={iconList.linkedinIcon}
                                alt="LinkedIn Icon"
                                style={iconSize}
                                className="hideOnMobile"
                            />
                        </a>
                    </li>
                    <li  style={navLinkStyle}>
                        <a
                            href={socialMedia.github}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="GitHub"
                            >
                            <img
                                src={iconList.githubIcon}
                                alt="Github Icon"
                                style={iconSize}
                                className="hideOnMobile"
                            />
                        </a>
                    </li>
                    <li  style={navLinkStyle}>
                        <a
                            href={socialMedia.medium}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Medium"
                            >
                            <img
                                src={iconList.mediumIcon}
                                alt="Medium Icon"
                                style={iconSize}
                                className="hideOnMobile"
                            />
                        </a>
                    </li>
                </ul>
                <ul style={inPageRowStyle}>
                    <li style={projectLink} >
                        <a href="#projects">
                            <p className="navSection">Projects</p>
                        </a>
                    </li>
                    <li style={contactLink}>
                        <a href="#contact">
                            <p className="navSection">Contact</p>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;